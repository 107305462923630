// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-info-text-tsx": () => import("./../src/pages/InfoText.tsx" /* webpackChunkName: "component---src-pages-info-text-tsx" */),
  "component---src-pages-intro-text-tsx": () => import("./../src/pages/IntroText.tsx" /* webpackChunkName: "component---src-pages-intro-text-tsx" */),
  "component---src-pages-landing-tsx": () => import("./../src/pages/Landing.tsx" /* webpackChunkName: "component---src-pages-landing-tsx" */)
}

